import React from "react";
import PropTypes from "prop-types";

/**
 * Edit existing student's firstName, lastName, gradeApplying, currentGrade and overrideStatus
 * @name EditRequestForm
 * @param {{}} student
 * @return {JSX.Element}
 */
const EditRequestForm = ({ formDispatch, formState, selectedRequest }) => {
    /* @kinderObj {[]} */
    const kinderObj = [
        { value: "K_HALF_AM", display: "Half Day - AM" },
        { value: "K_HALF_PM", display: "Half Day - PM" },
        { value: "K_FULL", display: "Full Day" }
    ];
    /* @languageObj {[]} */
    const languageObj = [
        { value: "FRENCH", display: "French" },
        { value: "MANDARIN", display: "Mandarin" },
        { value: "SPANISH", display: "Spanish" }
    ];

    /* @statusObj {[]} */
    const statusObj = [
        { value: "ACCEPT_OFFER", display: "Accepted Offer" },
        { value: "CANCELLED_REQUEST", display: "Cancelled Request" },
        { value: "CHANGED_ENROLLMENT", display: "Changed Enrollment" },
        { value: "CHOICE_INCOMPLETE", display: "Choice Incomplete" },
        { value: "DENY_OFFER", display: "Denied Offer" },
        { value: "DENY_REQUEST", display: "Denied Request" },
        { value: "ENROLLED", display: "Enrolled" },
        { value: "MAKE_OFFER", display: "Offer Made" },
        { value: "MAKE_OFFER_PENDING", display: "Offer Pending" },
        { value: "NO_RESPONSE", display: "No Response" },
        { value: "REQUEST", display: "Request Made" },
        { value: "WAIT_LIST", display: "On Wait List" }
        // { value: "UNENROLLED", display: "Unenrolled" }
    ];

    /**
     * Controlled input change handler
     * @name handleOnChange
     * @param {{}} e
     */
    const handleOnChange = (e) => {
        const { name, value } = e.target;

        formDispatch({
            type: "text",
            field: name,
            payload: value
        });
    };

    return (
        <form id="request-form">
            <div className="input-row">
                {selectedRequest.kinderChoice && (
                    <label htmlFor="kinder-offer">
                        Kinder Offer
                        <select
                            id="kinder-offer"
                            name="kinderOffer"
                            onChange={handleOnChange}
                            value={formState.kinderOffer}
                        >
                            <option value="">- Select -</option>
                            {kinderObj.map((kinder, index) => {
                                const uniqueKey = `kinder-option-${index}`;
                                return (
                                    <option key={uniqueKey} value={kinder.value}>
                                        {kinder.display}
                                    </option>
                                );
                            })}
                        </select>
                    </label>
                )}
                {selectedRequest.languageChoice && (
                    <label htmlFor="language-offer">
                        Language Offer
                        <select
                            id="language-offer"
                            name="languageOffer"
                            onChange={handleOnChange}
                            value={formState.languageOffer}
                        >
                            <option value="">- Select -</option>
                            {languageObj.map((language, index) => {
                                const uniqueKey = `language-option-${index}`;
                                return (
                                    <option key={uniqueKey} value={language.value}>
                                        {language.display}
                                    </option>
                                );
                            })}
                        </select>
                    </label>
                )}
            </div>
            <div className="input-row mt-3">
                <label htmlFor="grade-name">
                    Score
                    <input
                        id="score"
                        name="score"
                        onChange={handleOnChange}
                        type="number"
                        value={formState.schoolChoiceScore}
                    />
                </label>
                <label htmlFor="grade-applying">
                    Status
                    <select id="grade-applying" name="gradeApplying" onChange={handleOnChange} value={formState.status}>
                        <option value="">- Select -</option>
                        {statusObj.map((status, index) => {
                            const uniqueKey = `status-option-${index}`;
                            return (
                                <option key={uniqueKey} value={status.value}>
                                    {status.display}
                                </option>
                            );
                        })}
                    </select>
                </label>
            </div>
            <div className="input-row mt-3">
                <label htmlFor="notes">
                    Notes
                    <textarea
                        cols={75}
                        id="notes"
                        maxLength={1000}
                        name="notes"
                        onChange={handleOnChange}
                        rows={3}
                        value={formState.notes}
                    />
                </label>
            </div>
        </form>
    );
};

EditRequestForm.propTypes = {
    formDispatch: PropTypes.func.isRequired,
    formState: PropTypes.oneOfType([PropTypes.object]).isRequired,
    selectedRequest: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default EditRequestForm;

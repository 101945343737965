export const stringGradeOrdinal = {
    PRE: -3,
    K_HALF_AM: -2,
    K_HALF_PM: -1,
    K_FULL: 0,
    K: 1,
    FIRST: 2,
    SECOND: 3,
    THIRD: 4,
    FOURTH: 5,
    FIFTH: 6,
    SIXTH: 7,
    SEVENTH: 8,
    EIGHTH: 9,
    NINTH: 10,
    TENTH: 11,
    ELEVENTH: 12,
    TWELFTH: 13
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.footer-avail-slots-container {
  display: flex;
}
.footer-avail-slots-container #text {
  color: #0A4064;
  font-weight: 650;
  font-size: 1.1rem;
}
.footer-avail-slots-container .footer-item-container {
  border: 1px solid #A7A9AC;
  width: 20%;
  padding: 8px 20px;
  background: #C0DEF0;
  font-weight: 600;
  font-size: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/global/Colors.scss","webpack://./src/styles/AvailableSlotsFooter.scss"],"names":[],"mappings":"AAAA;;;EAGI,sBAAA;ACCJ;;ADJA;;;EAGI,sBAAA;ACOJ;;AAPA;EACI,aAAA;AAUJ;AATI;EACI,cDSE;ECRF,gBAAA;EACA,iBAAA;AAWR;AARI;EACI,yBAAA;EACA,UAAA;EACA,iBAAA;EACA,mBDWI;ECVJ,gBAAA;EACA,iBAAA;AAUR","sourcesContent":["*,\n*::before,\n*::after {\n    box-sizing: border-box;\n}\n$lt-blue: #7DB7CC;\n$information: #00AEEF;\n$information-hover: #02BFFF;\n$text: #414042;\n$white: #EDEDED;\n$shadow: #525151;\n$red: #CB321A;\n$green: #C7CC68;\n$DCSD-logo-green: #84ae30;\n$dashboard-blue: #007bff;\n$dk-blue: #0A4064;\n$important: #FEBF31;\n$highlight: #FDF9D0;\n$grey0: #EDf0F1;\n$grey1: #D1D3D4;\n$grey2: #A7A9AC;\n$grey3: #808080;\n$grey3a: #707070;\n$grey4: #636161;\n$grey4a: #444343;\n$grey5: #333;\n$pale-blue: #C0DEF0;\n$pearl-dk-blue: #0762a7;\n$pearl-blue: #0669B3;\n$pearl-blue-hover: #1786DB;\n$lt-pearl-blue: #63AEDE;\n$lt-pearl-blue-hover: #4589B3;\n$teal-logo: #46A8B0;\n\n$pale-blue-green: #9acdd2;\n$pale-blue-green-outline: #75c2c9;\n\n$pale-purple: #bca0d3;\n$pale-purple-outline: #946eb4;\n","@import \"../styles/global/Colors\";\n@import \"../styles/global/globalMixins\";\n\n.footer-avail-slots-container {\n    display: flex;\n    #text {\n        color: $dk-blue;\n        font-weight: 650;\n        font-size: 1.1rem;\n    }\n\n    .footer-item-container {\n        border: 1px solid $grey2;\n        width: 20%;\n        padding: 8px 20px;\n        background: $pale-blue;\n        font-weight: 600;\n        font-size: 1.2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

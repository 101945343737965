import React, { useCallback, useContext, useEffect, useReducer, useState } from "react";
import Header from "../components/Header.jsx";
import { toast, ToastContainer } from "react-toastify";
import FormReducer from "../utils/FormReducer.jsx";
import { GlobalContext } from "../components/contextProvider/ContextProvider.jsx";
import RbA from "../components/rba/RbA.jsx";
import ActionButton from "../components/formInputs/buttons/ActionButton.jsx";
import EditRequestForm from "../components/formComponents/EditRequestForm.jsx";
import { EVALUATION_SITE } from "../utils/auth/config.js";
import DcsdDialog from "../components/modals/DcsdDialog.jsx";
import FacetedSearch from "../components/formComponents/facetedSearch/FacetedSearch.jsx";
import { getCompleteDateTime } from "../utils/DateFormatter.jsx";
import LoadingSvg from "../components/loadingSvg/LoadingSvg.jsx";
import OpenEnrollmentDao from "../dao/OpenEnrollmentDao.jsx";
import { stringGradeOrdinal } from "../const/UtilConsts.jsx";
import Toolbar from "../components/Toolbar.jsx";
import RequestsTable from "../components/tables/RequestsTable.jsx";

import "../styles/Requests.scss";

/**
 * @TODO: Faceted Search needs an approved method for altering params, facetField, and exclFieldList.
 * The current global vars do not update in real time, and including these in the get calls is wasteful.
 * Perhaps we can change these variables to useRef (as suggested by the linter) - WS 2024-10-03
 * Display the School Choice Management
 * @name Requests
 * @segment
 * @return {JSX.Element}
 */
const Requests = () => {
    const { dispatch, state } = useContext(GlobalContext);
    /* eslint-disable-next-line */
    const { oeActiveDistrictWindow, schoolChoiceParents, token, userDetails } = state || {};

    const initialFormState = {
        kinderOffer: "",
        languageOffer: "",
        notes: "",
        schoolChoiceScore: 0,
        status: ""
    };

    const [formState, formDispatch] = useReducer(FormReducer, initialFormState);

    const [facetFields, setFacetFields] = useState(null);
    const [facetPagination, setFacetPagination] = useState(null);
    const [loader, setLoader] = useState(true);
    const [searchString, setSearchString] = useState("");
    const [open, setOpen] = useState("false");
    const [pageNum, setPageNum] = useState(1);
    const [params, setParams] = useState({});
    const [requests, setRequests] = useState(null);
    const [selectedFacets, setSelectedFacets] = useState([]);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [siblings, setSiblings] = useState(null);
    const [parentRequests, setParentRequests] = useState(null);

    const allowedRolesArray = ["OPEN_ENROLLMENT_ADMIN", "PRINCIPAL", "REGISTRAR"];

    // these values change, based on if user role is OPEN_ENROLLMENT_ADMIN or not
    let exclFieldList = "";
    let facetField =
        "currentGrade,educationalTrack,enrollmentRound,gradeApplying,kinderChoice,kinderOffer,languageChoice,languageOffer,schoolChoiceSchoolName,statusDisplayName,studentSchoolName";
    // these values do not change
    const facetLimit = 100;
    const numRows = 50;
    const sort = "schoolChoiceScore:desc,randomNumber:desc,completionDate:asc";

    /**
     * Set the selectedRequest and open the Edit Dialog
     * @name editRequest
     * @param {{}} request
     */
    const editRequest = (request) => {
        setSelectedRequest(request);
        setOpen("edit");
    };

    /**
     * Return the Action Buttons for the Edit Dialog
     * @name getEditActions
     * @return {Node}
     */
    const getEditActions = () => {
        return (
            <>
                <ActionButton
                    className="action-button-cancel"
                    label="Cancel"
                    onClick={() => {
                        setOpen("false");
                        setSelectedRequest(null);
                    }}
                />
                <ActionButton
                    className="action-button-reg"
                    disable={loader}
                    label="Submit"
                    onClick={() => {
                        handleEdit();
                    }}
                />
            </>
        );
    };

    const getBackToDialogActions = () => {
        return (
            <>
                <ActionButton
                    className="action-button-cancel"
                    label="Cancel"
                    onClick={() => {
                        window.location.replace("/home");
                    }}
                />
                <a href={`${EVALUATION_SITE}/profile`}>
                    <ActionButton
                        ariaLabel="Navigate to profile setting page"
                        className="action-button-reg"
                        label="Set Profile"
                    ></ActionButton>
                </a>
            </>
        );
    };

    /**
     * Download a csv file of current search results
     * @name getOeRequestsExport
     */
    const getOeRequestsExport = () => {
        if (isOeAdmin()) {
            // alter facet fields to include IEP Status
            facetField =
                "currentGrade,educationalTrack,enrollmentRound,gradeApplying,kinderChoice,kinderOffer,languageChoice,languageOffer,schoolChoiceSchoolName,statusDisplayName,studentIepStatus,studentSchoolName";
        } else {
            const { userAttributeDto } = userDetails;
            const { userAttributeMap } = userAttributeDto;
            const { CURRENT_PREDOMINANT_SCHOOL } = userAttributeMap;
            // alter schoolChoiceLocationKey and schoolYearKey
            exclFieldList =
                "currentGrade,studentIepStatus,parentGuid,participatingSchoolId,schoolChoiceLocationKey,schoolChoiceSchoolName,studentLocationKey,studentFeederLocationKey,status,schoolYearKey,parentUsername,personId";
            params.schoolChoiceLocationKey = CURRENT_PREDOMINANT_SCHOOL.locationKey;
            params.schoolYearKey = oeActiveDistrictWindow.schoolYearKey;
        }
        const paramRequests = {
            exclFieldList,
            facetField,
            facetLimit,
            numRows: 50000,
            pageNum: 0,
            searchString,
            sort
        };
        const requestParams = FacetedSearch.getRequestParams(params, paramRequests);
        const options = {
            action: "oeRequestsSearchableExport",
            params: requestParams,
            token
        };
        setLoader(true);
        // This is the Export statement to create the .CSV file
        OpenEnrollmentDao(options).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            // ---------------------  this is the name of the file ↓
            link.setAttribute("download", "OpenEnrollmentRequests.csv");
            document.body.appendChild(link);
            link.click();
            setLoader(false);
        });
    };

    /**
     * Sort the Facets Alphabetically
     * @name getSortedFields
     * @param {[]} fields
     * @returns
     */
    const getSortedFields = (fields) => {
        let fullySorted = fields;
        if (fields && fields.length > 0) {
            fields.sort((a, b) => {
                return a.fieldName > b.fieldName ? 1 : -1;
            });
            fullySorted = fields.reduce((results, obj) => {
                const { facetEntries, fieldName } = obj;
                if (["currentGrade", "gradeApplying"].includes(fieldName)) {
                    facetEntries.sort((a, b) => {
                        return stringGradeOrdinal[a.value] > stringGradeOrdinal[b.value] ? 1 : -1;
                    });
                } else {
                    facetEntries.sort((a, b) => {
                        return a.value > b.value ? 1 : -1;
                    });
                }
                results.push(obj);

                return results;
            }, []);
        }

        return fullySorted;
    };

    /**
     * Submit the request edits
     * @name handleEdit
     */
    const handleEdit = () => {
        const { kinderOffer, languageOffer, status, schoolChoiceScore, notes } = formState;
        //-------------------------------------------------
        // following comments left in for next ticket
        //-------------------------------------------------
        // if (
        //     kinderOffer === "" ||
        //     languageOffer === "" ||
        //     schoolChoiceScore === "" ||
        //     status === ""
        // ) {
        //     toast.error("All fields are required! Please enter values that are missing and try again.", {
        //         autoClose: false
        //     });
        //     return false;
        // }
        toast.dismiss();
        const {
            completionDate,
            createdDate,
            key,
            kinderChoice,
            languageChoice,
            lastUpdateDate,
            participatingSchoolId,
            randomNumber,
            student
        } = structuredClone(selectedRequest);
        const data = {
            key,
            participatingSchoolId,
            priority: 1,
            languageChoice: languageChoice === "" ? null : languageChoice,
            languageOffer: languageOffer === "" ? null : languageOffer,
            kinderChoice: kinderChoice === "" ? null : kinderChoice,
            kinderOffer: kinderOffer === "" ? null : kinderOffer,
            notes: notes.trim(),
            randomNumber,
            score: schoolChoiceScore,
            status,
            student,
            createdDate: getCompleteDateTime(createdDate),
            completionDate: getCompleteDateTime(completionDate),
            lastUpdateDate: getCompleteDateTime(lastUpdateDate)
        };
        const options = {
            action: "oeRequestUpdate",
            data,
            key,
            token
        };
        setLoader(true);
        OpenEnrollmentDao(options).then((response) => {
            if (response) {
                const { errors, payload } = response.data;
                if (payload && requests) {
                    toast.success("Successfully updated the open enrollment request", { autoClose: false });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else if (errors && errors.length) {
                    let errorMessage = "Unable to update open enrollment request.";
                    errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                    toast.error(`${errorMessage}`, {
                        autoClose: false,
                        closeOnClick: true,
                        style: { width: "100%", whiteSpace: "break-spaces" }
                    });
                }
            } else {
                toast.error("There was a problem updating the open enrollment request.");
            }
            setTimeout(() => {
                setLoader(false);
                setSelectedRequest(null);
                setOpen("false");
            }, 3000);
        });

        return true;
    };

    /**
     * Is the user an Open Enrollment Admin
     * @name isOeAdmin
     * @return {bool}
     */
    const isOeAdmin = useCallback(() => {
        const { roleDtos } = userDetails;
        const adminObj = roleDtos.find((obj) => obj.role.toUpperCase() === "OPEN_ENROLLMENT_ADMIN") || null;

        return adminObj ? true : false;
    }, [userDetails]);

    /**
     * Query the Open Enrollment Requests searchable service
     * @name getOeRequests
     * @callback
     * @type {(function(): void)|*}
     */
    const getOeRequests = useCallback(() => {
        if (isOeAdmin()) {
            /* eslint-disable */
            // alter facet fields to include IEP Status
            facetField =
                "currentGrade,educationalTrack,enrollmentRound,gradeApplying,kinderChoice,kinderOffer,languageChoice,languageOffer,schoolChoiceSchoolName,statusDisplayName,studentIepStatus,studentSchoolName";
            /* eslint-enable */
        } else {
            const { userAttributeDto } = userDetails;
            const { userAttributeMap } = userAttributeDto;
            const { CURRENT_PREDOMINANT_SCHOOL } = userAttributeMap;
            /* eslint-disable */
            // alter schoolChoiceLocationKey and schoolYearKey
            exclFieldList =
                "currentGrade,studentIepStatus,parentGuid,participatingSchoolId,schoolChoiceLocationKey,schoolChoiceSchoolName,studentLocationKey,studentFeederLocationKey,status,schoolYearKey,parentUsername,personId";
            /* eslint-enable */
            params.schoolChoiceLocationKey = CURRENT_PREDOMINANT_SCHOOL.locationKey;
            params.schoolYearKey = oeActiveDistrictWindow.schoolYearKey;
        }
        const paramRequests = {
            facetField,
            facetLimit,
            numRows,
            pageNum,
            searchString,
            sort
        };
        const requestParams = FacetedSearch.getRequestParams(params, paramRequests);
        const options = {
            action: "oeRequestsSearchableRead",
            params: requestParams,
            token
        };
        setLoader(true);
        // sets the payload
        OpenEnrollmentDao(options).then((response) => {
            if (response) {
                const { payload } = response.data;
                if (payload) {
                    setRequests(payload.results);
                    const sorted = getSortedFields(payload.facetFields);
                    setFacetFields(sorted);
                    setFacetPagination(payload.facetPaginationInfoDto);
                }
            }
            setLoader(false);
        });
    }, [exclFieldList, facetField, isOeAdmin, oeActiveDistrictWindow, pageNum, params, searchString, token]);

    /**
     * Return an array of objects of parents who have multiple siblings
     * @name getUniqueParents
     * @async
     * @param {{}} reqs
     * @return {[]} parentKids
     */
    const getUniqueParents = async (reqs) => {
        const parents = reqs.reduce((results, dto) => {
            const existingParents = schoolChoiceParents?.filter((obj) => obj.parentUsername === dto.parentUsername);
            if ((!existingParents || existingParents.length === 0) && dto.parentUsername) {
                results.push(dto.parentUsername);
            }

            return results;
        }, []);

        const unique = Array.from(new Set(parents));

        // Using Promise.all to wait for each OpenEnrollmentDao call to resolve
        const parentKids = await Promise.all(
            unique.map(async (un) => {
                const requestParams = {
                    facetField: "",
                    facetLimit,
                    numRows,
                    pageNum: 0,
                    searchString: `parentUsername=${un}`,
                    sort
                };
                const options = {
                    action: "oeRequestsSearchableRead",
                    params: requestParams,
                    token
                };

                // Await the Promise returned by OpenEnrollmentDao
                const response = await OpenEnrollmentDao(options);
                const tmp = [];

                if (response) {
                    const { payload } = response.data;
                    if (payload && payload.results && payload.results.length > 0) {
                        payload.results.forEach((dto) => {
                            const tmpObj = {
                                firstName: dto.firstName,
                                key: dto.key,
                                lastName: dto.lastName,
                                parentGuid: dto.parentGuid,
                                parentUsername: dto.parentUsername,
                                participatingSchoolId: dto.participatingSchoolId,
                                schoolChoiceLocationKey: dto.schoolChoiceLocationKey,
                                status: dto.status,
                                student: dto.student
                            };
                            tmp.push(tmpObj);
                        });
                    }
                }

                return tmp;
            })
        );

        // Flatten the array of arrays to get a single array with all objects
        return parentKids.flat();
    };

    useEffect(() => {
        if (requests) {
            const tmpSchoolChoiceParents = schoolChoiceParents || [];
            getUniqueParents(requests).then((response) => {
                if (response) {
                    setParentRequests(response);
                    tmpSchoolChoiceParents.push([...response]);
                    const newTemp = Array.from(new Set(tmpSchoolChoiceParents));
                    dispatch({
                        type: "SchoolChoiceParents",
                        payload: newTemp
                    });
                }
            });
        }
        // including the callback function getUniqueParents causes a race condition - for now, do not include the
        // function as a dependency
        /* eslint-disable-next-line */
    }, [dispatch, requests, schoolChoiceParents]);

    /**
     * Get all siblings from a parent who are applying to the same school
     * @todo need to take the ever expanding parentRequests and only call the searchable service for parents that do
     * not exist in the context
     */
    useEffect(() => {
        if (parentRequests && requests) {
            const sibs = requests.reduce((results, req) => {
                const sibling = parentRequests.filter(
                    (pReq) =>
                        parseInt(pReq.participatingSchoolId, 10) === parseInt(req.participatingSchoolId, 10) &&
                        parseInt(pReq.parentGuid, 10) === parseInt(req.parentGuid, 10) &&
                        parseInt(pReq.student, 10) !== parseInt(req.student, 10) &&
                        !["CANCELLED_REQUEST", "CHOICE_CREATED", "CHOICE_INCOMPLETE"].includes(pReq.status)
                );
                if (sibling && sibling.length > 0) {
                    results.push(...sibling);
                }

                return results;
            }, []);
            setSiblings(sibs.flat());
        }
    }, [parentRequests, requests]);

    /**
     * Retrieve Open Enrolled Requests
     */
    useEffect(() => {
        if (oeActiveDistrictWindow && token && userDetails) {
            const { userAttributeDto } = userDetails;
            const { userAttributeMap } = userAttributeDto;
            const { CURRENT_PREDOMINANT_SCHOOL } = userAttributeMap;
            if (CURRENT_PREDOMINANT_SCHOOL) {
                getOeRequests();
            } else {
                setOpen("no-predominate-location");
            }
        }
    }, [getOeRequests, isOeAdmin, oeActiveDistrictWindow, token, userDetails]);

    /**
     * Update formState when a new request is selected
     */
    useEffect(() => {
        if (selectedRequest) {
            const tmpFormState = structuredClone(formState);
            tmpFormState.kinderOffer = selectedRequest.kinderOffer || "";
            tmpFormState.languageOffer = selectedRequest.languageOffer || "";
            tmpFormState.notes = selectedRequest.notes || "";
            tmpFormState.schoolChoiceScore = selectedRequest.schoolChoiceScore || 0;
            tmpFormState.status = selectedRequest.status || "";
            formDispatch({
                type: "reset",
                payload: { ...tmpFormState }
            });
        }
        /* eslint-disable-next-line */
    }, [selectedRequest]);

    /**
     * Get the schoolYearKey and dispatch to state (contextProvider)
     * this call is from OE current district window
     */
    useEffect(() => {
        if (token && !oeActiveDistrictWindow) {
            setLoader(true);
            const options = {
                action: "oeActiveDistrictWindowRead",
                token
            };
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        dispatch({
                            type: "OeActiveDistrictWindow",
                            oeActiveDistrictWindow: payload
                        });
                    }
                }
                setLoader(false);
            });
        }
    }, [dispatch, oeActiveDistrictWindow, token]);

    return (
        <RbA allowedRoles={allowedRolesArray} redirect="/notFound">
            <Header />
            <ToastContainer style={{ width: "50%" }} />
            <div className="gutter-95">
                <Toolbar label="Manage OE Requests" showBackButton />
                {facetFields && (
                    <FacetedSearch
                        exportCsvHandler={getOeRequestsExport}
                        facetFields={facetFields}
                        includeKeyword
                        params={params}
                        selectedFacets={selectedFacets}
                        setPageNum={setPageNum}
                        setParams={setParams}
                        setSearchString={setSearchString}
                        setSelectedFacets={setSelectedFacets}
                    />
                )}
                <div className="no-data-message">
                    <h4 className="no-data-message-heading">
                        The following is a list of Open Enrollment School Choice requests for the current school year.
                    </h4>
                    <br />
                    Results, which are limited to <strong>{numRows} per page</strong>, can be searched by keyword or by
                    using the search filters displayed. You can click on the edit pencil to make adjustments to{" "}
                    <strong>Kinder Offer</strong>, <strong>Language Offer</strong>, <strong>Score</strong>,{" "}
                    <strong>Status</strong> and <strong>Notes</strong>.
                </div>
                {requests && (
                    <>
                        <div className="requests-table">
                            {facetPagination && (
                                <RequestsTable
                                    editRequest={editRequest}
                                    facetPagination={facetPagination}
                                    isOeAdmin={isOeAdmin}
                                    requests={requests}
                                    setPageNum={setPageNum}
                                    siblings={siblings}
                                />
                            )}
                            <div className="legend">
                                <h4>Legend</h4>
                                <p className="out-of-district">
                                    Student new to DCSD or applying to their current/feeder location.
                                </p>
                                <p>
                                    <i className="bi bi-people-fill" /> Sibling Applied
                                </p>
                                <p>
                                    <span className="iep">IEP</span> Individualized Education Plan
                                </p>
                            </div>
                        </div>
                        <DcsdDialog
                            actions={getEditActions()}
                            ariaLabel="Edit Open Enrollment Request"
                            backdrop="staticBackdrop"
                            hasCloseX
                            id="edit"
                            onHide={() => {
                                setOpen("false");
                                setSelectedRequest(null);
                            }}
                            open={open}
                            title="Edit Open Enrollment Request"
                        >
                            {selectedRequest && (
                                <>
                                    <p>
                                        Use this form to edit the following information for{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {selectedRequest?.firstName} {selectedRequest?.lastName}
                                        </span>
                                        .
                                    </p>
                                    <EditRequestForm
                                        formState={formState}
                                        formDispatch={formDispatch}
                                        selectedRequest={selectedRequest}
                                    />
                                </>
                            )}
                            {loader && (
                                <div className="dialog-loader">
                                    Processing... Please Wait <LoadingSvg />
                                </div>
                            )}
                        </DcsdDialog>
                    </>
                )}
                <DcsdDialog
                    actions={getBackToDialogActions()}
                    ariaLabel="Must have primary school location dialog"
                    hasCloseX={false}
                    id="no-predominate-location"
                    onHide={() => {
                        setOpen("false");
                    }}
                    open={open}
                    title="Your Predominant Location is not set"
                >
                    <div>Please set your Predominant School Location to view the Open Enrollment Manager. </div>
                </DcsdDialog>
            </div>
            {loader && <LoadingSvg />}
        </RbA>
    );
};

export default Requests;

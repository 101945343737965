import React, { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Pager from "../formComponents/facetedSearch/Pager";

/**
 * Display a table of school choice requests
 * @name RequestsTable
 * @param {func} editRequest
 * @param {{}} facetPagination
 * @param {bool} isOeAdmin
 * @param {[]} requests
 * @param {func} setPageNum
 * @param {[]} siblings
 * @return {JSX.Element}
 */
const RequestsTable = ({ editRequest, facetPagination, isOeAdmin, requests, setPageNum, siblings }) => {
    const [expanded, setExpanded] = useState([]);

    const headers = [
        "Score",
        "Student",
        "Guardian",
        "Student Birthdate",
        "Current Grade",
        "Grade Applying",
        "Kinder Choice",
        "Kinder Offer",
        "Language Choice",
        "Language Offer",
        "Current Enrollment",
        "Feeder Location",
        "Status",
        "Notes",
        "Edit"
    ];
    const idx = headers.findIndex((item) => item === "Kinder Choice");
    if (idx !== -1 && isOeAdmin()) {
        headers.splice(idx, 0, "School Choice");
    }
    /**
     * an OE Admin sees an additional column for schoolChoice
     */
    const colSpan = headers.length;

    /**
     * Show a tooltip displaying the student's name and any notes associated with the request
     * @name getTooltip
     * @param {{}} req
     * @return {node}
     */
    const getTooltip = (req) => {
        return (
            <div className="tool-tip-container">
                <h6>
                    <b>
                        <u>
                            {req.firstName} {req.lastName}
                        </u>
                    </b>
                </h6>
                <span>Notes: {req.notes}</span>
            </div>
        );
    };

    /**
     * Display or hide the request's notes in an additional row
     * @name handleClick
     * @param {string} action
     * @param {string} key
     */
    const handleClick = (action, key) => {
        let tmpExpanded = expanded;
        if (action === "show") {
            tmpExpanded.push(key);
        } else {
            const filtered = expanded.filter((item) => parseInt(item, 10) !== parseInt(key, 10));
            tmpExpanded = filtered;
        }
        setExpanded([...tmpExpanded]);
    };

    /**
     * onLoad, expand the notes row if any notes exist
     * @name showHide
     * @callback
     * @param {string} key
     * @return {bool}
     */
    const showHide = useCallback(
        (key) => {
            const match = expanded?.filter((item) => parseInt(item, 10) === parseInt(key, 10));

            return match && match.length > 0;
        },
        [expanded]
    );

    /**
     * Init the expanded array
     */
    useEffect(() => {
        const exp = requests.reduce((results, dto) => {
            if (dto.notes?.length > 0) {
                results.push(dto.key);
            }

            return results;
        }, []);

        setExpanded([...exp]);
    }, [requests]);

    return (
        <table className="mt-4">
            <thead className="sticky-top z-1">
                <tr>
                    <th className="icon-header"></th>
                    {headers.map((header, index) => {
                        const uniqueKey = `header-${index}`;
                        return <th key={uniqueKey}>{header}</th>;
                    })}
                </tr>
            </thead>
            <tbody>
                {requests.length > 0 &&
                    requests.map((request, index) => {
                        const uniqueKey = `request-${request.key}-${index}`;
                        const stripe = index % 2 === 0 ? "" : "stripe";
                        let oodClass = request.studentNumber ? "" : "out-of-district";
                        if (
                            parseInt(request.schoolChoiceLocationKey, 10) ===
                                parseInt(request.studentFeederLocationKey, 10) ||
                            parseInt(request.schoolChoiceLocationKey, 10) === parseInt(request.studentLocationKey, 10)
                        ) {
                            oodClass = "out-of-district";
                        }
                        let sib = false;
                        const {
                            educationalTrack,
                            currentGrade,
                            displayBirthdate,
                            firstName,
                            gradeApplying,
                            key,
                            kinderChoiceList,
                            kinderOffer,
                            languageChoiceList,
                            languageOffer,
                            lastName,
                            notes,
                            parentEmail,
                            parentFirstname,
                            parentLastname,
                            parentUsername,
                            parentTelephoneNumber,
                            schoolChoiceSchoolName,
                            schoolChoiceScore,
                            statusDisplayName,
                            studentFeederLocationName,
                            studentIepStatus,
                            studentNumber,
                            studentSchoolName
                        } = request;
                        const schoolName =
                            educationalTrack !== "Not Applicable"
                                ? `${schoolChoiceSchoolName} - ${educationalTrack}`
                                : schoolChoiceSchoolName;
                        if (siblings && siblings.length > 0) {
                            const sibMatch = siblings.filter(
                                (obj) => parseInt(obj.key, 10) === parseInt(request.key, 10)
                            );
                            if (sibMatch && sibMatch.length > 0) {
                                sib = true;
                            }
                        }
                        return (
                            <Fragment key={uniqueKey}>
                                <tr className={`${stripe} ${oodClass}`} key={uniqueKey}>
                                    <td className="icons" align="center">
                                        {sib && (
                                            <span className="sibling">
                                                <i className="bi bi-people-fill" />
                                            </span>
                                        )}
                                        {studentIepStatus === "true" && <span className="iep">IEP</span>}
                                    </td>
                                    <td className="center first">{schoolChoiceScore}</td>
                                    <td>
                                        {lastName}, {firstName}
                                        <br />
                                        {studentNumber}
                                    </td>
                                    <td>
                                        {parentLastname}, {parentFirstname}
                                        <br />
                                        {parentEmail}
                                        <br />
                                        {parentUsername}
                                        <br />
                                        {parentTelephoneNumber}
                                    </td>
                                    <td>{displayBirthdate}</td>
                                    <td>{currentGrade}</td>
                                    <td>{gradeApplying}</td>
                                    <td>{schoolName}</td>
                                    <td>
                                        {kinderChoiceList &&
                                            kinderChoiceList.map((item, index) => {
                                                const kinderKey = `kinder-choice-${index}`;
                                                return (
                                                    <div key={kinderKey} style={{ whiteSpace: "nowrap" }}>
                                                        {index + 1}: {item.replace(/_/g, " ")}
                                                        <br />
                                                    </div>
                                                );
                                            })}
                                    </td>
                                    <td>
                                        {kinderOffer && (
                                            <div style={{ whiteSpace: "nowrap" }}>{kinderOffer.replace(/_/g, " ")}</div>
                                        )}
                                    </td>
                                    <td>
                                        {languageChoiceList &&
                                            languageChoiceList.map((item, index) => {
                                                const languageKey = `language-choice-${index}`;
                                                return (
                                                    <div key={languageKey} style={{ whiteSpace: "nowrap" }}>
                                                        {index + 1}: {item.replace(/_/g, " ")}
                                                        <br />
                                                    </div>
                                                );
                                            })}
                                    </td>
                                    <td>{languageOffer}</td>
                                    {isOeAdmin() && <td>{studentSchoolName}</td>}
                                    <td>{studentFeederLocationName}</td>
                                    <td>{statusDisplayName}</td>
                                    <td>
                                        {notes?.length > 0 && (
                                            <>
                                                {showHide(key) ? (
                                                    <button
                                                        className="btn btn-transparent"
                                                        onClick={() => handleClick("hide", key)}
                                                        type="button"
                                                    >
                                                        <i
                                                            className="bi bi-dash-circle-fill"
                                                            style={{ color: "blue", fontSize: "1.2rem" }}
                                                        />
                                                    </button>
                                                ) : (
                                                    <OverlayTrigger
                                                        delay={{ hide: 5, show: 300 }}
                                                        overlay={(props) => (
                                                            <Tooltip className="tooltip-window" {...props}>
                                                                {getTooltip(request)}
                                                            </Tooltip>
                                                        )}
                                                        placement="top"
                                                    >
                                                        <button
                                                            className="btn btn-transparent"
                                                            onClick={() => handleClick("show", key)}
                                                            type="button"
                                                        >
                                                            <i
                                                                className="bi bi-plus-circle-fill"
                                                                style={{ color: "green", fontSize: "1.2rem" }}
                                                            />
                                                        </button>
                                                    </OverlayTrigger>
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td className="center last">
                                        <button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={() => editRequest(request)}
                                        >
                                            <i className="bi bi-pencil-square" />
                                        </button>
                                    </td>
                                </tr>
                                {showHide(key) && (
                                    <tr className={`${stripe} ${oodClass}`} key={`${uniqueKey}-notes`}>
                                        <td className="icons" />
                                        <td className="first notes" colSpan={colSpan}>
                                            <h6>
                                                <b>
                                                    <u>
                                                        {firstName} {lastName}
                                                    </u>
                                                </b>{" "}
                                                to <i>{schoolChoiceSchoolName}</i>
                                            </h6>
                                            <span>
                                                <b>Notes:</b>{" "}
                                            </span>
                                            {notes}
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        );
                    })}
                {requests.length === 0 && (
                    <tr>
                        <td className="center first last" colSpan={colSpan + 1}>
                            <div>No Requests Found</div>
                        </td>
                    </tr>
                )}
            </tbody>
            <tfoot>
                <tr>
                    <td className="icons" align="center" />
                    <td colSpan={colSpan}>
                        {facetPagination && requests.length > 0 && (
                            <div>
                                <Pager facetPagination={facetPagination} setPageNum={setPageNum} />
                            </div>
                        )}
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};

RequestsTable.propTypes = {
    editRequest: PropTypes.func.isRequired,
    facetPagination: PropTypes.oneOfType([PropTypes.object]).isRequired,
    isOeAdmin: PropTypes.func.isRequired,
    requests: PropTypes.instanceOf(Array),
    setPageNum: PropTypes.func.isRequired,
    siblings: PropTypes.instanceOf(Array)
};

RequestsTable.defaultProps = {
    requests: [],
    siblings: []
};

export default RequestsTable;
